import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
  Ratio,
} from "../../commons/Theme";

export const StyledModalGallery = styled.div`
  background-color: ${Colors.black};
  margin-bottom: ${(props) => props.marginBottom ?? 0};
  @media (${BP.ipad}) {
    width: 80%; 
    margin-left: 10%;
  }
`;

export const StyledModalGalleryThumbs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: ${Rem(24)};
  padding-bottom: ${Rem(8)};
  max-height: ${Rem(400)};

  @media (${BP.ipad}) {
    flex-direction: row;
    padding-top: ${Rem(50)};
    padding-bottom: ${Rem(26)};
    max-height: none;
  }
`;

export const StyledModalGalleryThumb = styled.div`
  ${Ratio(1, 1)};
  width: calc(50% - ${Rem(8)});
  margin-bottom: ${Rem(16)};
  margin-right: ${Rem(16)};
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  
  @media(${BP.ipad}) {
    width: calc((100% / 6) - ${Rem(16)});
    margin-right: 0;
    margin-bottom: ${Rem(16)};
  }
`;
